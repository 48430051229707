<template>
  <div class="page-login">
    <div class="title"
         v-if="false">
      <img src="../assets/icon-small-logo.png"
           class="logo" />
      <div class="divider"></div>
      <span class="name">后台系统</span>
    </div>
    <div class="middle">
      <div class="left"
           v-show="false">
        <div class="hl">哈雷戴维森 小程序后台</div>
        <div class="hl2">全新模式，畅快工作</div>
        <div class="hl3">极致透明的管理模式带来全面的创造创新，<br>
          进一步提升服务品质和用户体验。</div>
      </div>
      <div class="login">
        <span class="name">登录</span>
        <span class="tips"></span>
        <el-input class="userName"
                  prefix-icon="el-icon-user"
                  placeholder="请输入账号"
                  v-model="userName"></el-input>
        <el-input class="pwd"
                  prefix-icon="el-icon-lock"
                  placeholder="请输入密码"
                  show-password
                  v-model="pwd"></el-input>
        <el-button class="button"
                   type="primary"
                   v-on:click="login()">登录</el-button>
        <div class="err"
             v-show="err.length > 0">
          <i class="el-icon-warning"
             type="primary" />
          <span>{{err}}</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <span class="copyright">
        copyright@2020趣包科技出品
      </span>
      <span class="copyright">浙ICP备2020038345号</span>
    </div>
  </div>

</template>

<script>
import { login } from '../api/loginapi.js'
import store from 'store'
import storeKeys from '../../../store/storeKeys.js'
import md5 from 'js-md5'
export default {
  data () {
    return {
      userName: '',
      pwd: '',
      err: ''
    }
  },
  methods: {
    login () {
      if (!this.userName) {
        this.err = '请输入用户名'
        return
      }
      if (!this.pwd) {
        this.err = '请输入密码'
        return
      }
      const param = {
        user_name: this.userName,
        pwd: md5(this.pwd)
      }
      login(param).then(({ data: { code, data } }) => {
        if (code === 200) {
          store.set(storeKeys.TOKEN, data.token)
          this.$store.commit('saveUser', data)
          this.$store.commit('saveMenus', data.menus)
          this.$router.push('/')
        }
      })
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
.page-login {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100wh;
  .title {
    display: flex;
    align-items: center;
    padding: 18px 0;
    .logo {
      width: 30px;
      margin-left: 19%;
    }
    .divider {
      width: 1px;
      height: 16px;
      background: #bfbfbf;
      margin: 0 8px;
    }
    .name {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
  }
  .middle {
    flex: 1;
    background-image: url(../assets/login_bk.png);
    background-size: 100% auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .login {
      background: #ffffff;
      border-radius: 4px;
      width: 362px;
      padding: 18px;
      margin: 120px;
      .name {
        text-align: center;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        font-size: 20px;
        display: block;
      }

      .tips {
        display: block;
        text-align: center;
        margin-top: 6px;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
      }
      .userName {
        margin-top: 24px;
        height: 40px;
      }
      .pwd {
        margin-top: 24px;
        height: 40px;
      }
      .button {
        width: 100%;
        height: 40px;
        margin-top: 24px;
      }
      .err {
        display: flex;
        align-items: center;
        margin-top: 8px;
      }
      .err span {
        font-size: 14px;
        color: rgba(24, 144, 255, 1);
        margin-left: 7px;
      }
      .err i {
        width: 14px;
        height: 14px;
        color: rgba(24, 144, 255, 1);
      }
    }
    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 120px;
      .hl {
        font-weight: 600;
        color: #ffffff;
        font-size: 38px;
        line-height: 46px;
        display: block;
      }
      .hl2 {
        display: block;
        font-weight: 400;
        color: #ffffff;
        font-size: 24px;
        line-height: 32px;
        margin-top: 8px;
      }
      .hl3 {
        display: block;
        font-weight: 400;
        color: #ffffff;
        font-size: 16px;
        line-height: 24px;
        margin-top: 18px;
      }
    }
  }
  .footer {
    padding: 18px;
    text-align: center;
    .copyright {
      display: block;
    }
  }
}
</style>
