/*
 * @Author: your name
 * @Date: 2021-08-13 11:39:44
 * @LastEditTime: 2021-08-13 15:08:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\login\api\loginapi.js
 */
import { get } from "@/api/requests";
export const login = params => get("user/login", params);
